<template>
  <div class="about">
    <div class="about-us">
      <h1 class="about-us-title">{{ title }}</h1>
      <p class="about-us-1">{{ about }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  components: {
  },
  data() {
    return {};
  },

  props: {
    title: String,
    about: String,
  },
  created() { },
  mounted() { },
  methods: {}
}
</script>

<style scoped>
.about-us {
  color: #fff;
  font-size: 48px;
  text-align: center;
  height: 184px;
  padding-top: 100px;
  box-shadow: 0 10px 5px rgba(0,0,0,0.5);
}

.about-us-title {
  margin-bottom: 8px;
  border-bottom: solid 1px #cfcfcf;
  font-weight: 600;
  font-size: 48px;
  color: #fff;
  min-width: 196px;
  max-width: 300px;
  margin: 0 auto;
}

.about-us-1 {
  font-size: 24px;
  line-height: 42px;
}

.about {
  padding-top: 86px;
  width: 100vw;
  height: 284px;
  background-color: rgb(255, 255, 255);
  background: url('/assets/desc/003.png') center top no-repeat;

}
</style>