<template>
  <div>

    <Head />
    <Title :title="title" :about="about" />

    <div class="about-us">
      <h1>{{ company.about }}</h1>
      <ul>
        <li v-for="(value, key) in company.values" :key="key">{{ key }}: {{ value }}</li>
      </ul>
    </div>
    <Footer />
  </div>
</template>

<script>
import Head from '@/components/head.vue'
import Footer from '@/components/footer.vue';
import Title from '@/components/titles.vue'

export default {
  name: 'Home',
  components: {
    Head,
    Title,
    Footer
  },
  data() {
    return {
      title: '关于我们',
      about: 'About StarPlay',
      company: {
        about: '星玩网络是一家于2024年创立的网络信息公司，致力于为友商们带来最专业的游戏咨询和产品顾问。我们热爱游戏，贴近用户的视角，通过专业的数据分析来提供更加有效和切实的咨询服务。',
        values: {
          '专业': '我们长期关注游戏市场，以切实的数据进行有力的信息支持。',
          '激情': '我们对游戏充满热情，这种激情使我们更加理解玩家的喜好和需求。',
          '团队合作': '我们重视团队合作，相信通过长期的跟进和紧密的协作，帮助友商打造出更优秀的产品。'
        }
      }
    }
  },
}
</script>

<style scoped>
.about-us {
  height: 600px;
  font-family: Arial, sans-serif;
  margin-left:  10%;
  margin-right: 10%;
  justify-items: auto;
  padding-top: 5%;
  
}
.about-us h1{
  text-align: left;
  font-size: 25px;
  font-weight: 400;
  margin: 20px;

}

.about-us h1::before {
  content: '';
   display :flex;
  position: relative;
  left: 0;
  top: 25px;
  transform: translateY(1%);
  width: 10px; /* 色块宽度 */
  height: 25px; /* 色块高度 */
  background-color: rgb(97, 164, 208); /* 色块颜色 */
  border-radius: 2%; /* 圆形色块 */
  margin-right: 0px; /* 色块与文字之间的间距 */
  margin-left: -15px;
}

.about-us h2{
  text-align: left;
  font-weight: 50;
  margin: 20px;
}


.about-us ul{
  text-align: left;
  font-size: 25px;
  margin-block: 60px ;
  /* margin-left: 270px; */
}

.about-us ul li{
  margin: 10px;
}

</style>
